import 'bootstrap/dist/css/bootstrap.min.css';


function SubHeader(props){ //{name:"Agaram",place:"Nagercoil"}


    
    return(
        <h3>welcome {props.namedfd}</h3>

    )
}

export default SubHeader