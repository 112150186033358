import 'bootstrap/dist/css/bootstrap.min.css';
import SubHeader from './SubHeader';

function Header(props){ //{name:"Agaram",place:"Nagercoil"}

    return(
        <>
<nav className="navbar navbar-expand-lg navbar-light bg-light">
  <a className="navbar-brand" href="#">{props.name}</a>
  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
  </button>

 
</nav>
<SubHeader namedfd={props.name} />

</>
    )
}

export default Header