import logo from './logo.svg';
import './App.css';
import { Button, Alert } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState } from 'react';
import Header from './Header'
import Login from './Login'
function App() {

  let [name,changeName] = useState("Agaram")
  

  return (
    <div className="App container">
        <Header name={name} place="Nagercoil" /> {/* Header({name:"Agaram",place:"Nagercoil"}) */}
        <Header name={name} place="Nagercoil" /> {/* Header({name:"Agaram",place:"Nagercoil"}) */}
        <Header name={name} place="Nagercoil" /> {/* Header({name:"Agaram",place:"Nagercoil"}) */}
        <Login />
        <Button onClick={()=>changeName("Agaram Software Academy")}>Change name</Button>
    </div>
  );
}
export default App;
